@use 'styles/variables' as variables;

.errorContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem 0;
  border: 1px solid variables.$smoky-grey;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 20rem;

  .title {
    font-size: 1.3rem;
    font-weight: bold;
    color: variables.$warning-color;
  }

  .errorMessage {
    display: flex;
    text-align: center;
    color: variables.$grey-chateau;
    font-weight: 500;
    margin-top: 1rem;
  }

  .retryButton {
    border-radius: 5rem;
    margin-top: 1rem;
    width: 10rem;
  }
}
