@use 'styles/variables' as variables;

.container {
  display: flex;
  justify-content: space-between;

  .leftContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.15rem;
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.leadContainer {
  display: flex;
  justify-content: space-between;

  .leftContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.siteTile {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;

  .container {
    display: flex;
    column-gap: 5%;

    .siteDetails {
      display: flex;
      flex-direction: column;
      flex-basis: 70%;
      row-gap: 0.5rem;

      .siteId {
        font-weight: 700;
        font-size: 0.7rem;
        margin: 0.2rem 0 0.3rem 0;
        color: #393535;
      }
      .customerName {
        font-size: 1rem;
        margin: 0rem;
      }
      .location {
        color: variables.$grey-chateau;
        font-size: 0.8rem;
        margin: 0;
        font-weight: 700;
      }
      .typeAndStatus {
        font-size: 0.7rem;
        margin: 0;
        font-weight: 500;
      }
    }

    .imgAndDateContainer {
      display: flex;
      flex-direction: column;
      flex-basis: 25%;
      row-gap: 0.1rem;
      align-items: center;

      .imageContainer {
        .image {
          height: 100px;
          width: 150px;
        }
      }
      .date {
        color: variables.$dove-grey;
        font-size: 0.7rem;
        font-weight: 700;
        margin: 0rem;
      }
    }
  }
  .siteExtraInfo {
    color: variables.$bright-grey;
    font-weight: 500;
    font-size: 0.8rem;
    margin: 0;
  }
  .actionContainer {
    margin-top: 0.5rem;
    display: flex;
    column-gap: 1rem;

    .action {
      display: flex;
      column-gap: 0.3rem;
      align-items: center;

      .actionTitle {
        font-size: 0.9rem;
        margin: 0rem;
        font-weight: 600;
        color: #0189d1;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .siteTile {
    .container {
      .siteDetails {
        .siteId {
          font-size: 0.9rem;
        }
        .customerName {
          font-size: 1.5rem;
        }
        .location {
          font-size: 1rem;
        }
        .typeAndStatus {
          font-size: 1rem;
        }
      }
      .imgAndDateContainer {
        .imageContainer {
          .image {
            height: 150px;
            width: 180px;
          }
        }
        .date {
          font-size: 0.9rem;
        }
      }
    }
    .siteExtraInfo {
      font-size: 1rem;
    }
  }
}
