@use 'styles/variables' as variables;

.formPanel {
  background-color: variables.$white;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 0.4rem;
  padding: 0.25rem 0;

  .confirmationContainer {
    padding: 1rem 0 1rem 0;
    border: 0.03rem solid variables.$lighter-border-color;
    position: relative;
    text-align: center;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 20rem;

    .buttonWrapper {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .buttonContainer {
    display: flex;
    grid-column: 1/-1;
    margin-top: 0.5rem;

    .submitButton {
      margin-right: 0.5rem;
    }
  }

  .fixAtBottom {
    position: fixed;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    justify-content: space-between;
    background-color: white;
    text-align: center;
    z-index: 20;
  }

  @media only screen and (min-width: 601px) {
    .fixAtBottom {
      position: absolute;
    }
  }

  .status {
    padding-left: 0.625rem;
  }

  .label {
    font-weight: 600;
    font-size: 0.8rem;
    color: variables.$dove-grey;
    margin-bottom: 0.5rem;
  }

  .value {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 0.4rem;
  }

  .helperTextError {
    margin: 0.3rem 0;
    margin-left: 0.2rem;
    font-size: 0.7rem;
    color: variables.$error-color;
    font-weight: 500;
  }

  &.withEditMode {
    background-color: variables.$white;
    border: 1px solid variables.$smoky-grey;
    border-radius: 0.5rem;
    position: relative;
    margin: 0.5rem 0;

    .inner {
      margin: 1rem;
      border-left: variables.$primary-color 0.625rem solid;
      border-radius: 0.3rem;
      padding: 0.625rem 1.25rem;
      transition: border-left 0.2s;

      h2 {
        font-weight: 700;
        font-size: 1.05rem;
      }

      .value {
        input {
          font-size: 1rem;
        }
      }
    }
  }

  &.withReadMode {
    background-color: variables.$white;
    position: relative;
    margin: 0.5rem 0;

    .inner {
      border: variables.$smoky-grey 1px solid;
      border-radius: 8px;
      padding: 0.625rem 1.25rem;

      h2 {
        font-weight: 700;
        font-size: 1.05rem;
      }

      .label {
        padding-left: 0.33rem;
      }

      .editButton {
        visibility: hidden;
        position: absolute;
        top: 1rem;
        right: 1rem;
        visibility: inherit;
        border: 1px solid variables.$smoky-grey;
      }

      &:hover {
        border-color: variables.$lighter-border-color;
      }

      .value {
        input {
          font-size: 1rem;
        }
      }
    }
  }
  .textarea {
    border-color: rgba(variables.$grey-chateau, 0.8);
    border-radius: 0.25rem;
    width: 100%;
    color: variables.$bright-grey;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
  }
}
