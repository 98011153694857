@use 'styles/variables' as variables;

.cardContainer {
  .cardBody {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    cursor: pointer;

    .cardHeaderContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      align-items: center;
    }
    .cardDetails {
      display: flex;
      flex-direction: column;
    }
    .dateContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .actionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
