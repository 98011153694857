@use 'styles/variables' as variables;
// @use 'styles/mixins' as mixins;

.container {
  width: 100%;
  padding: 0.4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;

  .leadsList {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .filterWrapper {
    border-bottom: 1px solid variables.$light-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  .inner {
    width: 100%;

    .filterButton {
      background-color: variables.$whisper-grey;
      color: variables.$primary-font-color;
      font-size: 0.75rem;
      text-transform: capitalize;
      box-shadow: none;

      .filterIcon {
        color: variables.$primary-font-color;
        font-size: 0.875rem;
      }
    }

    .filterHeader {
      height: 9.5rem;
      margin-right: -1px;
      border-bottom: 1px solid variables.$light-border-color;
      padding-top: 1.8rem;

      .filterContainer {
        display: flex;
      }
    }
  }
}

.modalContainer {
  margin: 1rem;
}

.filter {
  border-bottom: 1px solid variables.$light-border-color;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  &:last-child {
    border-bottom: none;
  }

  .name {
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: capitalize;
    margin: 0.5rem 0;
    color: variables.$primary-font-color;
  }

  .optionsContainer {
    display: flex;
    flex-flow: column nowrap;

    .filterLabel {
      font-size: 0.9rem;
      color: variables.$primary-font-color;
    }

    .filterCheckbox {
      color: variables.$primary-font-color;

      svg {
        font-size: 1rem;
      }
    }

    .filterCheckboxChecked {
      color: variables.$primary-color;
    }

    .filterRadio {
      color: variables.$primary-font-color;

      svg {
        font-size: 1rem;
      }
    }

    .filterRadioChecked {
      color: variables.$primary-color;
    }

    span {
      &:first-child {
        padding: 0 0.8rem;
      }

      .viewMore {
        font-size: 0.8rem;
        color: variables.$secondary-color;
        font-weight: 600;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .optionsContainer {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
