.tilesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  
    .tile {
      flex-basis: 100%;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .tilesContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;
      row-gap: 1.5rem;
      
      .tile {
        flex-basis: 48%;
      }
    }
  }
  