@use 'styles/variables' as variables;

.container,
.containerMinimized,
.containerHorizontal {
  margin: 1rem 0 0 0;
  border: 1px solid variables.$smoky-grey;
  border-radius: 0.5rem;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 0;
    background-color: rgba(variables.$smoky-grey, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 0.4rem 0.4rem 0 0;
    box-shadow: #00000059 0px 0.5px 7px;
    cursor: pointer;

    .left {
      width: 100%;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 0.2rem;
    }
  }

  .row {
    margin: 1rem;

    .label {
      font-weight: 600;
      font-size: 0.7rem;
      color: variables.$dove-grey;
      margin-bottom: 0.25rem;
    }

    .value {
      font-weight: 500;
      font-size: 0.8rem;
      margin-top: 0;
    }
  }
}

.container,
.containerMinimized {
  animation: expandBox 0.15s;
  transition: all 0.15s ease-in-out;
}

.containerHorizontal {
  .dataRow {
    display: flex;
    flex-wrap: wrap;
  }
}

.containerMinimized {
  height: 2.5rem;
  overflow: hidden;
  animation: collapseBox 0.15s;
  transition: all 0.15s ease-in;
}

.image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 4rem;
  width: 4rem;
}

@keyframes collapseBox {
  0% {
    height: 15rem;
  }
  25% {
    height: 12rem;
  }
  50% {
    height: 9rem;
  }
  75% {
    height: 6rem;
  }
  100% {
    height: 2.5rem;
  }
}

@keyframes expandBox {
  0% {
    height: 2.5rem;
  }
  25% {
    height: 6rem;
  }
  50% {
    height: 9rem;
  }
  75% {
    height: 12rem;
  }
  100% {
    height: auto;
  }
}
