@use 'styles/variables' as variables;

.subTitle {
  font-weight: 600;
  font-size: 1.1rem;
}

.container {
  .statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
    padding: 0 0 1rem 0;
    flex-wrap: wrap;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    .targetStatusCard {
      width: 40%;
      min-width: 6.8rem;
      border-radius: 0.25rem;
      text-align: center;
      background-color: #f7f6f6;
      box-shadow: #00000059 0px 2px 5px;
      overflow: hidden;

      .label {
        padding: 0.25rem 0;
        background-color: variables.$logo-color;
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        font-weight: 600;
      }
      .value {
        padding: 0.25rem;
        color: variables.$logo-color;
        font-size: 0.85rem;
        font-weight: bold;
        background-color: #f7f6f6;
      }
    }

    .statusCard {
      width: 40%;
      min-width: 6.8rem;
      border-radius: 0.25rem;
      text-align: center;
      background-color: #f7f6f6;
      box-shadow: #00000059 0px 2px 5px;
      overflow: hidden;
      cursor: pointer;

      .label {
        padding: 0.3rem 0;
        background-color: variables.$primary-color;
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        font-weight: 600;
      }
      .value {
        padding: 0.35rem;
        color: variables.$primary-color;
        font-size: 0.85rem;
        font-weight: bold;
        background-color: #f7f6f6;
      }
    }
  }

  .leadsList {
    display: flex;
    flex-direction: column;
    row-gap: 1.3rem;
    padding: 0.7rem 0;
  }
}
