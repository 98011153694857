@use 'styles/variables' as variables;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  background-color: white;
  width: 100%;
  position: fixed;
  height: 3.5rem;
  border-bottom: 1px solid variables.$smoky-grey;

  .toolbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
    margin-left: auto;

    .logo {
      height: 1.3rem;
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid variables.$smoky-grey;

  .title {
    padding-top: 0.3rem;
    font-weight: 600;
    font-size: 1.3rem;

    Button {
      margin: 0;
    }
  }
}

@media only screen and (min-width: 601px) {
  .headerContainer {
    padding-left: 2rem;
  }
}

.desktopContainer {
  z-index: 10;
  background-color: white;
  height: 3.5rem;
  border-bottom: 1px solid variables.$smoky-grey;

  .logo {
    height: 1.3rem;
  }
}
