.container {
  padding: 1rem 0.7rem;
  color: #676667;
  border: 1px solid #c5c5c4;
  border-radius: 0.25rem;

  .legendName {
    font-size: 0.88rem;
    font-weight: 600;
  }
}

.smallContainer {
  padding: 0.3rem 0.4rem 0.6rem 0.6rem;
  color: #676667;
  border: 1px solid #c5c5c4;
  border-radius: 0.25rem;

  .legendName {
    font-size: 0.6rem;
    font-weight: 400;
  }
}
