@use 'styles/variables' as variables;
// @use 'styles/mixins' as mixins;

.container {
  padding-bottom: 0.5rem;
  min-width: 15rem;

  .inner {
    width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0;
    min-width: 11rem;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
    min-width: 0;
  }

  @media only screen and (min-width: 601px) {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
  }
}

.modalContainer {
  margin: 1rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .timelineAndLimitContainer {
    display: flex;
    justify-content: space-between;
  }
}

.modalFooterContainer {
  position: absolute;
  bottom: 1rem;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
